import React, { useState, useContext, useRef } from "react";
import { Route, HashRouter, useLocation, useHistory } from 'react-router-dom';
import LayoutCore from "./LayoutCore";
import LayoutSecondary from "./LayoutSecondary";
import LayoutLanding from "./LayoutLanding";
import LayoutShare from "./LayoutShare";
import firebase from 'firebase';
import { UserContext } from "../providers/UserProvider";
import Amplify,  { API } from 'aws-amplify';
import awsconfig from '../aws-exports';

import Dashboard from "./Dashboard";
import SripeUpgrade from "./SripeUpgrade";
import SripeCredits from "./SripeCredits";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Thanks from "./Thanks";
import PasswordReset from "./PasswordReset";

import HMOAppraisals from "./HMOAppraisals";
import HMOAppraisal from "./HMOAppraisal";
import HMOCreateAppraisal from "./HMOCreateAppraisal";

import BTLAppraisals from "./BTLAppraisals";
import BTLAppraisal from "./BTLAppraisal";
import BTLCreateAppraisal from "./BTLCreateAppraisal";

import FlipAppraisals from "./FlipAppraisals";
import FlipAppraisal from "./FlipAppraisal";
import FlipCreateAppraisal from "./FlipCreateAppraisal";

import ShareHMO from "./ShareHMO";
import ShareBTL from "./ShareBTL";
import ShareFlip from "./ShareFlip";

import settingsDefaultUserAppraisals from "./settings-default-user-appraisals";
import priceSettings from "../src/price-settings";

const Application = (props) => {

  //////////////////////////////////////////
  // Variables
  //////////////////////////////////////////
  const user                                      = useContext(UserContext);
  let [redirected, setRedirected]                 = useState(false);
  let redirectToUpgrade                           = useLocation().pathname.indexOf('upgradeaccount');
  let sharePage                                   = useLocation().pathname.indexOf('deals/');
  let apiPath                                     = 'stripeapidev';
  const creditToken                               = useLocation().search.replace('?credit_session_id=','');
  const subscriptionToken                         = useLocation().search.replace('?subscription_session_id=','');
  const history                                   = useHistory();
  const envMode                                   = props.envMode;

  const _inProgress = useRef(false); // Initial value _inProgress = false

  if('prod' === envMode) {
    apiPath = 'stripeapi';
  }

  Amplify.configure(awsconfig);

  if(user) {

    if(user.auth) {

      // user clicked on Upgrade Link
      if(redirectToUpgrade !== -1 && !redirected) {
        history.push('/upgrade');
        setRedirected(true);
      }

      // If subscription token present adjust user account type
      if(subscriptionToken && subscriptionToken.indexOf('?') === -1 && !_inProgress.current) {

        _inProgress.current = true;

        const body = {
          sessionId: subscriptionToken
        }

        API.post(apiPath, '/checkout-session', { body })
        .then(response => {  

          //console.log('response.sessionData', response.sessionData);

          let subscriptionId = false;
          let plan = false;

          response.sessionData.data.forEach(function (subscription) {
            if(subscription.status === 'active') {
              subscriptionId = subscription.id;
              plan = subscription.plan.ammount;
            }
          });

          //console.log(subscriptionId, plan);

          // user just purchased STANDARD subscription
          // credit their account, change subscription and remove the session success   
          if(response.sessionData.payment_status === 'paid' && response.sessionData.amount_total === priceSettings.standardPrice) {
            user.setUserCreditLimit(user.uid, 25);
            user.setUserType('standard');
            if(sharePage === -1) {
              history.push('/');
            }
            _inProgress.current = false;
          }


          // user just purchased PRO subscription
          // credit their account, change subscription and remove the session success   
          if(response.sessionData.payment_status === 'paid' && response.sessionData.amount_total === priceSettings.proPrice) {
            user.setUserCreditLimit(user.uid, false);
            user.setUserType('pro');
            if(sharePage === -1) {
              history.push('/');
            }
            _inProgress.current = false;
          }


        }).catch(function(err){
          //console.log('Error when Looking up Session', err);
          _inProgress.current = false;
        });

      }

      // we are dealing with a first time login, so we need to set the trial expiry date  
      if (!user.subscription && !_inProgress.current) {

        _inProgress.current = true;
        
        // set default appraisals for every user to aid onboarding
        let appraisalRef = firebase.database().ref('/appraisals/' + user.uid);
        appraisalRef.update(settingsDefaultUserAppraisals);
        appraisalRef.off();
       
        //  Start Create Customer --------------------
        const body = {
            email: user.email
        }

        API.post(apiPath, '/create-customer', { body })
        .then(customerData => {
          
          let coreRef = firebase.database().ref('/users/' + user.uid + '/subscription');
            let updates = {
              customerId: customerData.session.id
            };
            coreRef.update(updates);
          coreRef.off();
              
          //  Start Create Subscription ++++++++++++++++++++++++++++++++
          const body = {
            customerId: customerData.session.id
          }
          API.post(apiPath, '/create-subscription', { body })
          .then(subscriptionData => {
            let coreRef = firebase.database().ref('/users/' + user.uid + '/subscription');
              let updates = {
                customerId: customerData.session.id
              };
              user.setSubscription(customerData.session.id, 'trial');
              coreRef.update(updates);
              user.setUserType('trial');
              user.setUserCreditLimit(user.uid, 25);
            coreRef.off();

          })
          .catch(function(err){
            //console.log('Error when Creating Subscription', err);
          });
          //  End Create Subscription ++++++++++++++++++++++++++++++++


        })
        .catch(function(err){
          //console.log('Error when Creating Customer', err);
        });
        // End Create Customer --------------------

      }

      if(user.subscription && !_inProgress.current) {

        _inProgress.current = true;

        const body = {
          customerId: user.subscription.customerId
        }

        API.post(apiPath, '/check-subscription', { body })
          .then(subscriptionData => {

            // no data returned
            if(!subscriptionData || (!subscriptionData.session && !subscriptionData.session.data)) {
              if(user.subscription && !user.subscription.type) {
                user.setUserType('free');
                user.setUserCreditLimit(user.uid, 1);
              }
            } else {

              // Loop over all data we hold for subscriptions and process into some array for sorting
              // Active And Trial accounts exist we need to remove the trial ones
              // Multiple Active accouns to be removed
              let statusCollection = [];
              let subscriptionTrialIndexCollection = [];
              let subscriptionActiveIndexCollection = [];
              let subscriptionPlan = [];

              subscriptionData.session.data.forEach(function (subscription) {
                statusCollection.push(subscription.status);

                // it trial add to array so it can be removed;
                if(subscription.status === 'trialing') {
                  subscriptionTrialIndexCollection.push(subscription.id);
                }

                // if active add to array and if we have more than 1 we can remove the older ones
                if(subscription.status === 'active') {
                  subscriptionActiveIndexCollection.push(subscription.id);
                  subscriptionPlan.push(subscription.plan.amount);
                }
              });

              // we have a trial member who upgraded! Lets cancel that trial shall we!
              if(statusCollection.includes("active") && statusCollection.includes("trialing")) {

                subscriptionTrialIndexCollection.forEach(function (subscriptionItem, index) {
                  //  Start Create Subscription ++++++++++++++++++++++++++++++++
                  const body = {
                    subscriptionId: subscriptionItem
                  }
                  API.post(apiPath, '/remove-subscription', { body })
                  .then(function() {
                    //console.log('trial removed');
                  })
                  .catch(function(err){
                    //console.log('Error when Removing Subscription', err);
                  });
                  //  End Create Subscription ++++++++++++++++++++++++++++++++
                });

              }

              // we have a trial member who upgraded! Lets cancel that trial shall we!
              if(subscriptionActiveIndexCollection.length > 1) {

                //console.log(subscriptionActiveIndexCollection);
                let index = 0;

                subscriptionActiveIndexCollection.forEach(function (subscriptionItem, index) {
                  if(index > 0) {
                    //  Start Create Subscription ++++++++++++++++++++++++++++++++
                    const body = {
                      subscriptionId: subscriptionItem
                    }
                    API.post(apiPath, '/remove-subscription', { body })
                    .then(function() {
                      //console.log('Duplicate Active subscription removed');
                    })
                    .catch(function(err){
                      //console.log('Error when Removing Subscription', err);
                    });
                    //  End Create Subscription ++++++++++++++++++++++++++++++++
                  }
                  index ++;
                }); 

              }

              if(statusCollection.includes("active") || statusCollection.includes("trialing")) {

                if(statusCollection.includes("trialing")) {
                  if(user.subscription && !user.subscription.type) {
                    user.setUserType('trial');
                    user.setUserCreditLimit(user.uid, 25);
                    if(sharePage === -1) {
                      history.push('/');
                    }
                  }
                }

                if(statusCollection.includes("active")) {
                  if(user.subscription && subscriptionPlan.length) {
                    
                    if(subscriptionPlan[0] === priceSettings.standardPrice) {
                      // plan changed hard set credits
                      if(user.subscriptionType !== 'standard') {
                        user.setUserCreditLimit(user.uid, 25);
                      }
                      user.renewCredits(user.uid, 25, user);
                      user.setUserType('standard');
                    }

                    if(subscriptionPlan[0] === priceSettings.proPrice) {
                      user.setUserCreditLimit(user.uid, false);
                      user.setUserType('pro');
                    }

                    // handle historical users, if price subscribed is an old one make them Pro users
                    if(subscriptionPlan[0] === priceSettings.historicalAnnual || subscriptionPlan[0] === priceSettings.historicalMonthly) {
                      user.setUserCreditLimit(user.uid, false);
                      user.setUserType('pro');
                    }

                    if(sharePage === -1) {
                      history.push('/');
                    }
                  }
                }

              }
              else {
                //subscription Ended // previoulsy this had the following check [&& !user.subscription.type]
                if(user.subscription) {
                  // plan changed hard set credits
                  if(user.subscriptionType !== 'free') {
                    user.setUserCreditLimit(user.uid, 1, true);
                  } 
                  user.renewCredits(user.uid, 1, user);
                  user.setUserType('free');
                  if(sharePage === -1) {
                    history.push('/');
                  }
                }
              }

            }

          })
          .catch(function(err){
            //console.log('Error when Checking Subscription', err);
            if(user.subscription && !user.subscription.type) {
              user.setUserType('free');
              user.setUserCreditLimit(user.uid, 1);
              if(sharePage === -1) {
                history.push('/');
              }
            }
          });
      }

    
      return (
        <HashRouter>

          <LayoutCore envMode={envMode}>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/hmo" component={HMOAppraisals} />
            <Route path="/hmo/archive/:archive" render={(props) => <HMOAppraisals archive="true" {...props} />} />
            <Route path="/hmo/appraisal/:appraisalId" render={(props) => <HMOAppraisal
            appraisalId={props.match.params.appraisalId} {...props} />} />
            <Route path="/hmo/createAppraisal" component={HMOCreateAppraisal}  />

            <Route exact path="/btl" component={BTLAppraisals} />
            <Route path="/btl/archive/:archive" render={(props) => <BTLAppraisals archive="true" {...props} />} />
            <Route path="/btl/appraisal/:appraisalId" render={(props) => <BTLAppraisal
            appraisalId={props.match.params.appraisalId} {...props} />} />
            <Route path="/btl/createAppraisal" component={BTLCreateAppraisal}  />

            <Route exact path="/flips" component={FlipAppraisals} />
            <Route path="/flips/archive/:archive" render={(props) => <FlipAppraisals archive="true" {...props} />} />
            <Route path="/flips/appraisal/:appraisalId" render={(props) => <FlipAppraisal
            appraisalId={props.match.params.appraisalId} {...props} />} />
            <Route path="/flips/createAppraisal" component={FlipCreateAppraisal}  />

            <Route path="/deals/hmo/:appraisalId" render={(props) => <ShareHMO
              appraisalId={props.match.params.appraisalId} {...props} />} />
            <Route path="/deals/btl/:appraisalId" render={(props) => <ShareBTL
              appraisalId={props.match.params.appraisalId} {...props} />} />
            <Route path="/deals/flips/:appraisalId" render={(props) => <ShareFlip
              appraisalId={props.match.params.appraisalId} {...props} />} />

            <Route exact path="/upgrade" render={(props) => <SripeUpgrade type="upgrade" envMode={envMode} /> }/>
            <Route exact path="/limit-reached" render={(props) => <SripeUpgrade type="limit-reached" envMode={envMode} /> }/>
          </LayoutCore>


        </HashRouter>
      );
    } 
    else { // if !user.auth

      if(sharePage !== -1) { // if sharing
        return (
          <HashRouter>
            <LayoutShare>
              <Route path="/deals/hmo/:appraisalId" render={(props) => <ShareHMO
                appraisalId={props.match.params.appraisalId} {...props} />} />
              <Route path="/deals/btl/:appraisalId" render={(props) => <ShareBTL
                appraisalId={props.match.params.appraisalId} {...props} />} />
              <Route path="/deals/flips/:appraisalId" render={(props) => <ShareFlip
                appraisalId={props.match.params.appraisalId} {...props} />} />
            </LayoutShare>
          </HashRouter>
        )
      } else {
        return(
          <HashRouter>
            <LayoutSecondary>
              <Route exact path="/" component={SignIn} />
              <Route exact path="/upgradeaccount" component={SignIn} />
              <Route path="/signUp" component={SignUp} />
              <Route path="/thanks" component={Thanks} />
              <Route path="/passwordReset" component={PasswordReset} />
            </LayoutSecondary>
          </HashRouter>
        );
      }
    }
  } else { // if user == null
    return (
      <HashRouter>
        <Route path="*" component={LayoutLanding} />
      </HashRouter>
    )
  }

}

export default Application;
