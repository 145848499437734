import React, { useState, useContext } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { SlideDown } from 'react-slidedown'
import { auth } from '../firebase';
import { UserContext } from "../providers/UserProvider";
import UserSettings from "./UserSettings";
import TemplateManageBillingButton from "./templates/template-manage-billing-button";
import TemplateOnboarding from "./templates/template-onboarding";
import 'react-slidedown/lib/slidedown.css'
import SvgReactLogo from '../src/logo.svg';
import apiGlobal from "../store/api";

const LayoutCore = ({ children, envMode }) => {

  const [settingsBlock, setSettingsBlock] = useState(false);
  const [showKPIs, setShowKPIs] = useState(true);
  const [showNav, setShowNav] = useState((window.innerWidth > 1024));
  const [apiSettings] = apiGlobal(state => state.apiSettings);
  const location = useLocation();

  const user = useContext(UserContext);

  const ButtonClassName = "cursor-pointer relative nav-parent pl-12 text-white border-b border-pgNavy-400 p-3 flex hover:bg-pgNavy-900 hover:text-white text-sm";
  const ButtonActiveClassName = "nav-parent-active button-active bg-pgNavy-700";
  const ButtonClassNameSubNav = "sub-nav nav-item-active text-white pl-12 bg-pgNavy-700 p-3 block hover:bg-pgNavy-900 hover:text-white text-sm";
  const ButtonActiveClassNameSubNav = "nav-item-active nav-item-active--sub bg-pgNavy-700";

  const toggleSettingsPanel = () => {
    if(showKPIs) {
      setSettingsBlock(!settingsBlock);
    }
  }

  //////////////////////////////////////////
  // Effects
  //////////////////////////////////////////
  React.useEffect(
    () => {
      if(location.pathname.indexOf('flips') !== -1) {
        setShowKPIs(false);
      }
      else {
        setShowKPIs(true);
      }
    },
    [location]
  );

  const logout = () => {
    auth.signOut();
    window.location.href = "/index.html";
  }

  const toggleNav = () => {
    if(window.innerWidth < 1024) {
      setShowNav(!showNav);
    }
  }

    return (
    <div className="w-full flex lg:flex-col">
      
      <link rel="preconnect" href="https://fonts.gstatic.com" /><link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap" rel="stylesheet" />

      <TemplateOnboarding user={user} />

      <div className="w-full lg:flex lg:h-screen w-screen">

        <div className="flex flex-col w-full lg:w-1/6 bg-pgNavy-500 lg:h-full relative z-10">

          <NavLink onClick = {() => {toggleNav()}} className="block text-white text-3xl p-10 pl-5" to="/"><img src={SvgReactLogo} className="w-2/6 sm:w-1/6 lg:w-5/6" alt="Property Gambit" /></NavLink>

          <div className="flex mt-10 m-5 absolute right-0 top-0 items-center">

            <span onClick = {() => {toggleNav()}} className={`menu ${showNav?'active':''}`}>
              <svg width="40" height="40" viewBox="0 0 100 100" className="text-white lg:hidden">
                <path className="menu-line menu-line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className="menu-line menu-line2" d="M 20,50 H 80" />
                <path className="menu-line menu-line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </span>

            <span className={`text-white lg:hidden cursor-pointer ${showKPIs?'':' opacity-50'} `} onClick={() => {toggleSettingsPanel()}}><em className={`icon-cog text-3xl transition-transform transition duration-500 ease-in-out ${settingsBlock?' settings-active':''}`}></em></span>
          </div>

          <SlideDown transitionOnAppear={false}>

            {showNav &&
            <div className="flex flex-col border-t border-pgNavy-400 h-full content-bottom">

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassName} className={ButtonClassName} to="/" exact={true}><em className="icon-gauge text-pgNavy-300 absolute left-0 ml-5"></em> Dashboard</NavLink>

              <NavLink activeClassName={ButtonActiveClassName} className={ButtonClassName} to="/hmo">
                  <em className="icon-group text-pgNavy-300 absolute left-0 ml-5"></em>HMO <em className="icon-right-dir ml-auto"></em>
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/hmo" exact={true}>
                  HMO Appraisals
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/hmo/createAppraisal">
                  Create New Appraisal
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/hmo/archive/true">
                  Archive
              </NavLink>

              <NavLink activeClassName={ButtonActiveClassName} className={ButtonClassName} to="/btl">
                 <em className="icon-home text-pgNavy-300 absolute left-0 ml-5"></em>BTL <em className="icon-right-dir ml-auto"></em>
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/btl" exact={true}>
                  BTL Appraisals
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/btl/createAppraisal">
                  Create New Appraisal
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/btl/archive/true">
                  Archive
              </NavLink>

              <NavLink activeClassName={ButtonActiveClassName} className={ButtonClassName} to="/flips">
                 <em className="icon-arrows-cw text-pgNavy-300 absolute left-0 ml-5"></em>Flips <em className="icon-right-dir ml-auto"></em>
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/flips" exact={true}>
                  Flip Appraisals
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/flips/createAppraisal">
                  Create New Appraisal
              </NavLink>

              <NavLink onClick = {() => {toggleNav()}} activeClassName={ButtonActiveClassNameSubNav} className={ButtonClassNameSubNav} to="/flips/archive/true">
                  Archive
              </NavLink>

              <div className="user-nav w-full lg:w-1/6">

                <a href="https://instagram.com/propertygambit" target="_blank" rel="noopener noreferrer" className={ButtonClassName}><em className="icon-instagram text-pgNavy-300 absolute left-0 ml-5"></em>Follow on Instagram</a>

                <div className="flex flex-col">

                  <>

                  {(user.subscriptionType === 'pro' || user.subscriptionType === 'standard') && <TemplateManageBillingButton
                    stripePublicKey={apiSettings[envMode].stripePublicKey}
                    apiName={apiSettings[envMode].apiName}
                    apiEndpoint="/manage-billing"
                    returnUrl={apiSettings[envMode].redirect}
                    customerId={user.subscription.customerId}
                     /> 
                  }

                  <span onClick = {() => {logout()}} className={ButtonClassName + ` cursor-pointer`}><em className="icon-cancel-circled text-pgNavy-300 absolute left-0 ml-5"></em> Logout</span>
                  </>

                </div>

                
                  <>

                  {user.subscriptionType === 'trial' &&
                  <span className="sub-nav w-full text-left lg:text-center nav-item-active text-white pl-6 bg-pgNavy-500 p-3 block text-sm">Free Trial Active</span>
                  }

                  {user.subscriptionType === 'free' &&
                  <span className="sub-nav w-full text-left lg:text-center nav-item-active text-white pl-6 bg-pgNavy-500 p-3 block text-sm">Free Package</span>
                  }

                  {user.subscriptionType === 'standard' &&
                  <span className="sub-nav w-full text-left lg:text-center nav-item-active text-white pl-6 bg-pgNavy-500 p-3 block text-sm">Standard Package</span>
                  }

                  {user.subscriptionType === 'pro' &&
                  <span className="sub-nav w-full text-left lg:text-center nav-item-active text-white pl-6 bg-pgNavy-500 p-3 block text-sm">Pro Package</span>
                  }

                  {(user.limit) && 
                    <span className={`block lg:hidden mx-2 text-center mb-2 sm:w-2/3 p-1 px-4 rounded rounded-full text-sm ${(user.limit && !user.credits)?' bg-red-500 text-pgNavy-500':'bg-white text-pgNavy-500'}`}>{user.credits?user.credits:'0'} Credits Remaining</span>
                  }
                  {(!user.limit) && 
                    <span className={`block lg:hidden mx-2 text-center p-1 mb-2 sm:w-2/3 px-4 rounded rounded-full text-sm ${(user.limit && !user.credits)?' bg-red-500 text-pgNavy-500':'bg-white text-pgNavy-500'}`}>Unlimited Credits</span>
                  }

                  <NavLink className="mx-2 lg:mx-auto block cursor-pointer bg-gradient-to-r sm:w-2/3 m-auto mb-5 text-center from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-1 text-pgBlue-500 text-sm" to="/upgrade">
                    View Pricing
                  </NavLink>
                  
                  </>
              </div>

            </div>
            }

          </SlideDown>

        </div>
        <div className="flex w-full lg:w-5/6 flex-row">
          <div className="flex-row w-full">
            <div className="bg-pgNavy-500 w-full flex justify-end items-center pr-5 lg:p-2">
              {(user.limit) && 
                <span className={`hidden lg:block mr-4 p-1 px-4 rounded rounded-full text-sm ${(user.limit && !user.credits)?' bg-red-500 text-pgNavy-500':'bg-pgNavy-400 text-white'}`}>{user.credits?user.credits:'0'} Credits Remaining</span>
              }
              {(!user.limit) && 
                <span className={`hidden lg:block mr-4 p-1 px-4 rounded rounded-full text-sm ${(user.limit && !user.credits)?' bg-red-500 text-pgNavy-500':'bg-pgNavy-400 text-white'}`}>Unlimited Credits</span>
              }

              <span className={`hidden ${showKPIs?'':' opacity-50'} lg:flex text-pgNavy-500 bg-pgAqua-500 pl-4 pr-2 rounded rounded-full items-center text-sm cursor-pointer hover:bg-white`} onClick={() => {toggleSettingsPanel()}}>Change KPIs <em className={`icon-cog text-xl ml-2 transition-transform transition duration-500 ease-in-out ${settingsBlock?' settings-active':''}`}></em></span>
              
            </div>
            <UserSettings show={settingsBlock} />
            {children}
          </div>
        </div>
        <div className="hidden lg:block lg:w-1/6 bg-pgNavy-500 lg:h-full fixed z-0"></div>

      </div>

    </div>
  ) 
};

export default LayoutCore;

