import React from "react";
import useGlobal from "../../store";
import { UserContext } from "../../providers/UserProvider";

const moduleUpload = (user, remotePath, setThumbnail) => {

  const path = "https://pacific-lake-82221.herokuapp.com/" + remotePath;
  setThumbnail(path);

};

 export default moduleUpload;